import React from "react";
import queryString from "query-string";
import nProgress from "nprogress";
import Bowser from "bowser";
import PageWrapper from "./src/components/PageWrapper/PageWrapper";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import posthog from "posthog-js";

export const onClientEntry = () => {
  const parsed: any = queryString.parse(window.location.search) || {};
  if (document.referrer) {
    parsed.referrer = document.referrer;
  }
  parsed.browser = Bowser.parse(window.navigator.userAgent);
  sessionStorage.setItem("utm_source", JSON.stringify(parsed));
  nProgress.configure({ showSpinner: false });

  if (!sessionStorage.getItem("landing_page")) {
    sessionStorage.setItem("landing_page", location.href);
  }

  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.nr-data.net"] },
    }, // NREUM.init
    info: {
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "NRJS-b056381a71846e7084e",
      applicationID: "1589032242",
      sa: 1,
    }, // NREUM.info
    loader_config: {
      accountID: "6230937",
      trustKey: "6230937",
      agentID: "1589032242",
      licenseKey: "NRJS-b056381a71846e7084e",
      applicationID: "1589032242",
    }, // NREUM.loader_config
  };

  if (process.env.NODE_ENV !== "development") {
    // The agent loader code executes immediately on instantiation.
    new BrowserAgent(options);
  }


  if (process.env.NODE_ENV !== "development") {
    posthog.init("phc_ruRGJ7yj0prMitFtB7N8A0jofmckODxzGlcZc6u5kQB", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== prevLocation?.pathname) {
    nProgress.done();
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }),
      100
    );
  }
};

export const onRouteUpdateDelayed = () => {
  nProgress.start();
};

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>;
};
